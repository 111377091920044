<template>
  <v-navigation-drawer app floating color="primary" :value="!collapseSidebar">
    <v-toolbar color="primary darken-1">
      <v-spacer></v-spacer>
      <v-img :src="imageLogo" max-width="150" />
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-list dense shaped dark>
      <v-list-item-group mandatory v-model="selectedSidebarItem" @change="goto">
        <v-subheader>Tính năng</v-subheader>
        <v-list-item
          v-for="(item, index) in sidebarItems"
          :key="`drawer-item-${index}`"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import _findIndex from "lodash/findIndex";

import ImageLogo from "@/assets/images/illustrations/bePro-logo-white-text.svg";

export default {
  data: () => ({
    imageLogo: ImageLogo,
    sidebarItems: [
      /*
      {
        name: "home",
        text: "Thống kê",
        icon: "mdi-chart-line",
      },
      */
      {
        name: "home",
        text: "Sự kiện",
        icon: "mdi-calendar-arrow-right",
      },
    ],
    selectedSidebarItem: null,
  }),
  computed: {
    ...mapState("layout", {
      collapseSidebar: (state) => state.collapseSidebar,
    }),
  },
  methods: {
    setSelectDrawerItem() {
      let nameRoute = this.$route.name;

      this.selectedDrawerItem = _findIndex(
        this.drawerItems,
        (item) => item.name == nameRoute
      );
    },
    goto(value) {
      let name = this.sidebarItems[value].name;
      let nameRoute = this.$route.name;

      if (name != nameRoute) {
        this.$router.push({ name });
      }
    },
  },
  watch: {
    "$route.name"() {
      this.setSelectDrawerItem();
    },
  },
  mounted() {
    this.setSelectDrawerItem();
  },
};
</script>
