<template>
  <v-main>
    <v-container
      fill-height
      fluid
      :style="{
        background: `url(${imageBackground}) no-repeat`,
        backgroundSize: 'cover',
      }"
    >
      <v-row align="center" justify="center">
        <v-col align="center" justify="center" @click="() => login()">
          <v-btn rounded elevation="12" color="primary">
            <v-icon> mdi-google </v-icon>
            <span class="ml-2"> Đăng nhập bằng tài khoản Google </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";
import ImageBackground from "@/assets/images/background/background.svg";

export default {
  data: () => ({
    imageBackground: ImageBackground,
  }),
  methods: {
    ...mapActions("layout", {
      setAlert: "setAlert",
    }),
    ...mapActions("authentication", {
      loginApp: "login",
    }),
    async login() {
      await this.loginApp();
    },
  },
};
</script>
