<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark icon v-bind="attrs" v-on="on">
        <v-avatar color="orange" size="48">
          <img
            v-if="!isEmpty(get(profile, 'avatarUrl'))"
            :src="get(profile, 'avatarUrl')"
          />
          <span v-else class="text-h5">{{ avatarName }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <v-list max-width="300">
      <v-list-item>
        <v-list-item-avatar>
          <img
            v-if="!isEmpty(get(profile, 'avatarUrl'))"
            :src="get(profile, 'avatarUrl')"
          />
          <span v-else class="text-h5">{{ avatarName }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ get(profile, "name") }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ get(profile, "email") }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ get(profile, "phone") }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item-group>
        <v-list-item @click="() => logout()">
          <v-list-item-title> Đăng xuất </v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

export default {
  data: () => ({
    name: "abc",
  }),
  computed: {
    ...mapState("authentication", {
      profile: (state) => state.profile,
    }),
    avatarName() {
      console.log(this.profile);

      let name = _get(this.profile, "name");
      let splitName = name.split(" ");

      let displayName = splitName[splitName.length - 1][0];

      return displayName;
    },
  },
  methods: {
    ...mapActions("authentication", {
      logoutApp: "logout",
    }),
    isEmpty(item) {
      return _isEmpty(item);
    },
    get(item, ref) {
      return _get(item, ref);
    },
    logout() {
      this.logoutApp();

      this.$router.push({ name: "login" });
    },
  },
};
</script>
