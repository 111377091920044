import Vue from "vue";
import Vuex from "vuex";

import authentication from "./modules/authentication.module";
import action from "./modules/action.module";
import event from "./modules/event.module";
import layout from "./modules/layout.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication,
    action,
    event,
    layout,
  },
  strict: process.env.NODE_ENV !== "production",
});
