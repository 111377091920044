import Fetch from "@/helpers/fetch";
import {
  EVENT_LIST_URL,
  EVENT_CREATE_URL,
  EVENT_GET_BY_ID,
  EVENT_UPDATE_URL,
  EVENT_DELETE_URL,
} from "@/configs/api";

class EventService {
  static async list(query = {}) {
    let response = await Fetch.get(EVENT_LIST_URL, {
      query,
      authHeader: true,
    });

    return response;
  }

  static async getById(eventId) {
    let urlRegex = /:id/i;
    let url = EVENT_GET_BY_ID.replace(urlRegex, eventId);
    let response = await Fetch.get(url, {
      authHeader: true,
    });

    return response;
  }

  static async create(event) {
    let response = Fetch.post(EVENT_CREATE_URL, event, {
      authHeader: true,
    });

    return response;
  }

  static async update(eventId, data) {
    let urlRegex = /:id/i;
    let url = EVENT_UPDATE_URL.replace(urlRegex, eventId);

    let response = Fetch.put(url, data, {
      authHeader: true,
    });

    return response;
  }

  static async delete(eventId, deleteType) {
    let urlRegex = /:id/i;
    let url = EVENT_DELETE_URL.replace(urlRegex, eventId);

    let response = Fetch.delete(url, {
      query: {
        deleteType,
      },
      authHeader: true,
    });

    return response;
  }
}

export default EventService;
