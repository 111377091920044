import _get from "lodash/get";

import ActionService from "../../services/Action.service";

const initialState = {
  actions: [],
  lastPage: 1,
  total: 0,
  currPage: 1,

  configs: {},

  actionsInEvent: [],
  fileUploaded: {},
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    resetActionsInEvent({ commit }) {
      commit("RESET_ACTIONS_IN_EVENT");
    },
    resetFileUploaded({ commit }) {
      commit("RESET_FILE_UPLOADED");
    },
    async create({ dispatch }, action) {
      let response = await ActionService.create(action);

      let { code, data } = response;
      if (code != 201) {
        let payload = {
          message: " Không thể tạo tác vụ",
          type: "error",
        };

        dispatch("layout/alert", payload, { root: true });

        return false;
      }

      let payload = {
        message: "Tác vụ tạo thành công",
        type: "success",
      };

      dispatch("layout/alert", payload, { root: true });

      return data;
    },
    async update({ dispatch }, payload) {
      let id = _get(payload, "id");
      if (id <= 0) {
        let alert = {
          message: "Có lỗi trong quá trình thực thi",
          type: "error",
        };

        dispatch("layout/alert", alert, { root: true });

        return false;
      }

      let response = await ActionService.update(id, payload);

      let { code } = response;
      if (code != 200) {
        let alert = {
          message: "Không thể cập nhật tác vụ",
          type: "error",
        };

        dispatch("layout/alert", alert, { root: true });

        return false;
      }

      let alert = {
        message: "Tác vụ đã được cập nhật",
        type: "success",
      };

      dispatch("layout/alert", alert, { root: true });
    },
    async delete({ dispatch }, actionId) {
      let response = await ActionService.delete(actionId);

      let { code } = response;
      if (code != 200) {
        let payload = {
          message: " Không thể tạo tác vụ",
          type: "error",
        };

        dispatch("layout/alert", payload, { root: true });

        return false;
      }

      let payload = {
        message: "Tác vụ đã được xoá",
        type: "success",
      };

      dispatch("layout/alert", payload, { root: true });

      return true;
    },
    async getByEventId({ dispatch, commit }, eventId) {
      let response = await ActionService.getByEventId(eventId);

      let { code, data } = response;

      if (code == 201) {
        let payload = {
          message: "Sự kiện không có tác vụ",
          type: "warning",
        };

        dispatch("layout/alert", payload, { root: true });

        return false;
      }

      if (code != 200) {
        let payload = {
          message: " Không thể lấy tác vụ",
          type: "error",
        };

        dispatch("layout/alert", payload, { root: true });

        return false;
      }

      commit("SET_ACTIONS_IN_EVENT", data);
    },
    async getConfig({ commit, dispatch }) {
      let response = await ActionService.getConfig();

      let { code, data } = response;

      if (code != 200) {
        let payload = {
          message: " Không thể lấy cấu hình tác vụ",
          type: "error",
        };

        dispatch("layout/alert", payload, { root: true });

        return false;
      }

      commit("SET_CONFIGS", data);
    },
    async upload({ commit, dispatch }, formData) {
      let response = await ActionService.upload(formData);

      let { code, data } = response;
      if (code != 201) {
        let payload = {
          message: " Không thể upload file",
          type: "error",
        };

        dispatch("layout/alert", payload, { root: true });

        return false;
      }

      let payload = {
        message: "File upload thành công",
        type: "success",
      };

      dispatch("layout/alert", payload, { root: true });

      commit("SET_FILE_UPLOADED", data);
    },
  },
  mutations: {
    RESET_ACTIONS(state) {
      state.actions = [];

      state.lastPage = 1;
      state.currPage = 1;
      state.total = 0;
    },
    SET_ACTIONS(state, { actions, lastPage, total, currPage }) {
      state.actions = [...state.actions, ...actions];

      state.lastPage = lastPage;
      state.currPage = currPage;
      state.total = total;
    },
    RESET_ACTIONS_IN_EVENT(state) {
      state.actionsInEvent = [];
    },
    SET_ACTIONS_IN_EVENT(state, actions) {
      state.actionsInEvent = actions;
    },
    SET_CONFIGS(state, configs) {
      state.configs = configs;
    },
    SET_FILE_UPLOADED(state, file) {
      state.fileUploaded = file;
    },
    RESET_FILE_UPLOADED(state) {
      state.fileUploaded = {};
    },
  },
};
