import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { setCookie, getCookie, eraseCookie } from "../../helpers/cookies";

import AuthenticationService from "../../services/Authentication.service";

const initialState = {
  token: getCookie("token"),
  profile: null,
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    setToken({ commit }, payload) {
      const { token, exp } = payload;
      if (_isEmpty(token)) {
        return false;
      }

      commit("SET_TOKEN", token);
      setCookie("token", token, exp);

      return true;
    },
    async login() {
      let response = await AuthenticationService.login();
      if (_get(response, "code") == 200) {
        let url = _get(response, "data.uri");

        window.location.href = url;
      }
    },
    logout({ commit }) {
      commit("SET_TOKEN", null);
      eraseCookie("token");
    },
    async getProfile({ commit, dispatch }) {
      let response = await AuthenticationService.getProfile();
      if (_isEmpty(response)) {
        let payload = {
          message: "Không tìm thấy thông tin tài khoản",
          type: "error",
        };
        dispatch("layout/alert", payload, { root: true });

        return;
      }

      const { data } = response;

      commit("SET_PROFILE", data);
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_PROFILE(state, profile) {
      state.profile = profile;
    },
  },
};
