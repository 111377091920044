import Fetch from "@/helpers/fetch";
import { LOGIN_URL, PROFILE_URL } from "@/configs/api";

class AuthenticationService {
  static async login() {
    let response = await Fetch.get(LOGIN_URL);

    return response;
  }

  static async getProfile() {
    let response = await Fetch.get(PROFILE_URL, {
      authHeader: true,
    });

    return response;
  }
}

export default AuthenticationService;
