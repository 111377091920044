import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import EventService from "../../services/Event.service";

const initialState = {
  events: [],
  lastPage: 1,
  total: 0,
  currPage: 1,

  event: {},
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    resetEvent({ commit }) {
      commit("RESET_EVENT");
    },
    async list({ state, commit, dispatch }) {
      commit("RESET_EVENTS");

      let page = 1;
      do {
        let query = {
          pagination: {
            page,
          },
        };
        let response = await EventService.list(query);

        if (_isEmpty(response) || _get(response, "code") != 200) {
          let payload = {
            message: "Không có sự kiện",
            type: "warning",
          };
          dispatch("layout/alert", payload, { root: true });

          return;
        }

        let { data } = response;

        let currPage = _get(data, "current_page");
        let lastPage = _get(data, "last_page");
        let total = _get(data, "total");
        let events = _get(data, "data");

        commit("SET_EVENTS", { events, lastPage, total, currPage });

        page += 1;
      } while (state.currPage < state.lastPage);
    },
    async create({ dispatch, commit }, event) {
      let response = await EventService.create(event);

      let { code, data } = response;
      if (code != 201) {
        let payload = {
          message: " Không thể tạo sự kiện",
          type: "error",
        };

        dispatch("layout/alert", payload, { root: true });

        return false;
      }

      let payload = {
        message: "Sự kiện tạo thành công",
        type: "success",
      };

      dispatch("layout/alert", payload, { root: true });

      commit("SET_EVENT", data);
    },
    async getById({ commit, dispatch }, eventId) {
      let response = await EventService.getById(eventId);

      let { code, data } = response;
      if (code != 200) {
        let payload = {
          message: "Không tìm thấy sự kiện",
          type: "error",
        };

        dispatch("layout/alert", payload, { root: true });

        return false;
      }

      commit("SET_EVENT", data);
    },
    async update({ dispatch, commit }, payload) {
      let id = _get(payload, "id");
      if (id <= 0) {
        let alert = {
          message: "Có lỗi trong quá trình thực thi",
          type: "error",
        };

        dispatch("layout/alert", alert, { root: true });

        return false;
      }

      let response = await EventService.update(id, payload);

      let { code, data } = response;
      if (code != 200) {
        let alert = {
          message: "Không thể cập nhật sự kiện",
          type: "error",
        };

        dispatch("layout/alert", alert, { root: true });

        return false;
      }

      let alert = {
        message: "Sự kiện đã được cập nhật",
        type: "success",
      };

      dispatch("layout/alert", alert, { root: true });

      commit("SET_EVENT", data);
    },
    async delete({ dispatch, commit }, payload) {
      let id = _get(payload, "id");
      if (id <= 0) {
        let alert = {
          message: "Có lỗi trong quá trình thực thi",
          type: "error",
        };

        dispatch("layout/alert", alert, { root: true });

        return false;
      }

      let deleteType = _get(payload, "deleteType");
      let response = await EventService.delete(id, deleteType);

      let { code } = response;
      if (code != 200) {
        let alert = {
          message: "Không thể xoá sự kiện",
          type: "error",
        };

        dispatch("layout/alert", alert, { root: true });

        return false;
      }

      let alert = {
        message: "Sự kiện đã được xoá",
        type: "success",
      };

      dispatch("layout/alert", alert, { root: true });

      commit("RESET_EVENT");
    },
  },
  mutations: {
    RESET_EVENTS(state) {
      state.events = [];

      state.lastPage = 1;
      state.currPage = 1;
      state.total = 0;
    },
    RESET_EVENT(state) {
      state.event = {};
    },
    SET_EVENTS(state, { events, lastPage, total, currPage }) {
      state.events = [...state.events, ...events];
      state.lastPage = lastPage;
      state.currPage = currPage;
      state.total = total;
    },
    SET_EVENT(state, event) {
      state.event = event;
    },
  },
};
