<template>
  <v-app>
    <v-slide-x-transition mode="out-in">
      <component :is="layout"></component>
    </v-slide-x-transition>
    <v-snackbar
      right
      timeout="2000"
      v-model="snackbar"
      :color="get(alert, 'type')"
    >
      {{ get(alert, "message") }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import _get from "lodash/get";

import LoadingLayout from "@/views/Layouts/Loading.vue";
import BaseLayout from "@/views/Layouts/Base.vue";
import LoginLayout from "@/views/Layouts/Login.vue";

export default {
  components: {
    "loading-layout": LoadingLayout,
    "base-layout": BaseLayout,
    "login-layout": LoginLayout,
  },
  data: () => ({
    snackbar: false,
    interval: null,
  }),
  computed: {
    ...mapState("layout", {
      alert: (state) => state.alert,
    }),
    layout() {
      if (this.$route.meta.layout) {
        return this.$route.meta.layout + "-layout";
      }

      return "loading-layout";
    },
  },
  methods: {
    get(item, ref) {
      return _get(item, ref);
    },
  },
  watch: {
    alert() {
      this.snackbar = true;
    },
  },
  mounted() {},
};
</script>
