export const LOGIN_URL = `${process.env.VUE_APP_API_URL}/accounts/login?redirectUrl=${process.env.VUE_APP_URL}`;
export const PROFILE_URL = `${process.env.VUE_APP_API_URL}/accounts/detail`;

export const EVENT_LIST_URL = `${process.env.VUE_APP_API_URL}/events`;
export const EVENT_GET_BY_ID = `${process.env.VUE_APP_API_URL}/events/:id`;
export const EVENT_CREATE_URL = `${process.env.VUE_APP_API_URL}/events`;
export const EVENT_UPDATE_URL = `${process.env.VUE_APP_API_URL}/events/:id`;
export const EVENT_DELETE_URL = `${process.env.VUE_APP_API_URL}/events/:id`;
export const EVENT_GET_ACTION_BY_EVENT_ID = `${process.env.VUE_APP_API_URL}/events/:id/action`;

export const ACTION_CREATE_URL = `${process.env.VUE_APP_API_URL}/actions`;
export const ACTION_DELETE_URL = `${process.env.VUE_APP_API_URL}/actions/:id`;
export const ACTION_UPDATE_URL = `${process.env.VUE_APP_API_URL}/actions/:id`;
export const ACTION_CONFIG_URL = `${process.env.VUE_APP_API_URL}/actions/configs`;
export const ACTION_UPLOAD_URL = `${process.env.VUE_APP_API_URL}/actions/upload`;
