const initialState = {
  collapseSidebar: false,
  alert: {
    message: null,
    color: null,
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    alert({ commit }, payload) {
      const { message, type } = payload;

      commit("SET_ALERT", { message, type });
    },
    toggleSidebar({ state, commit }) {
      commit("SET_COLLAPSE_SIDEBAR", !state.collapseSidebar);
    },
  },
  mutations: {
    SET_ALERT(state, alert) {
      state.alert = alert;
    },
    SET_COLLAPSE_SIDEBAR(state, status) {
      state.collapseSidebar = status;
    },
  },
};
