import Vue from "vue";
import Router from "vue-router";
import _isEmpty from "lodash/isEmpty";

import { getCookie } from "./helpers/cookies";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "loading",
    meta: {
      title: "Loading",
      layout: "loading",
    },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Đăng nhập",
      layout: "login",
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Calendar/Calender.vue"),
    meta: {
      title: "Lịch gửi mail",
      layout: "base",
    },
  },
];

const router = new Router({
  mode: "history",
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
  routes: routes,
});

router.beforeEach((to, from, next) => {
  document.title = `BePro | ${to.meta.title}`;

  const publicPages = ["loading", "login"];
  const isAuthRequired = !publicPages.includes(to.name);
  const token = getCookie("token");

  if (isAuthRequired) {
    let { name, params, query } = to;
    let toRoute = JSON.stringify({ name, params, query });
    sessionStorage.setItem("toRoute", toRoute);

    if (_isEmpty(token)) {
      return next({ name: "login" });
    }
  }

  next();
});

export default router;
