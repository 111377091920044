<template>
  <div>
    <!-- Begin drawer -->
    <Sidebar></Sidebar>
    <!-- End drawer -->
    <!-- Begin app bar -->
    <v-app-bar app color="primary">
      <v-app-bar-nav-icon dark @click="toggleSidebar"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-text-field
        solo
        dense
        hide-details
        rounded
        label="Tìm kiếm mail"
        append-icon="mdi-magnify"
        v-model="searchMail"
      ></v-text-field>
      <v-spacer></v-spacer>
      <UserBox></UserBox>
    </v-app-bar>
    <!-- End app bar -->
    <!-- Begin main -->
    <v-main>
      <v-slide-y-reverse-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-reverse-transition>
    </v-main>
    <!-- End main -->
  </div>
</template>

<script>
import { mapActions } from "vuex";

import UserBox from "./Components/UserBox.vue";
import Sidebar from "./Components/Sidebar.vue";

export default {
  components: {
    UserBox,
    Sidebar,
  },
  data: () => ({
    drawer: true,
    searchMail: null,
  }),
  methods: {
    ...mapActions("layout", ["toggleSidebar"]),
  },
};
</script>
