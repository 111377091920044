import Fetch from "@/helpers/fetch";
import {
  ACTION_CREATE_URL,
  ACTION_DELETE_URL,
  EVENT_GET_ACTION_BY_EVENT_ID,
  ACTION_UPDATE_URL,
  ACTION_CONFIG_URL,
  ACTION_UPLOAD_URL,
} from "@/configs/api";

class ActionService {
  static async create(action) {
    let response = Fetch.post(ACTION_CREATE_URL, action, {
      authHeader: true,
    });

    return response;
  }
  static async delete(actionId) {
    let urlRegex = /:id/i;
    let url = ACTION_DELETE_URL.replace(urlRegex, actionId);
    let response = await Fetch.delete(url, {
      authHeader: true,
    });

    return response;
  }
  static async getByEventId(eventId) {
    let urlRegex = /:id/i;
    let url = EVENT_GET_ACTION_BY_EVENT_ID.replace(urlRegex, eventId);
    let response = await Fetch.get(url, {
      authHeader: true,
    });

    return response;
  }
  static async update(actionId, data) {
    let urlRegex = /:id/i;
    let url = ACTION_UPDATE_URL.replace(urlRegex, actionId);

    let response = Fetch.put(url, data, {
      authHeader: true,
    });

    return response;
  }
  static async getConfig() {
    let response = await Fetch.get(ACTION_CONFIG_URL, {
      authHeader: true,
    });

    return response;
  }
  static async upload(formData) {
    let response = await Fetch.upload(ACTION_UPLOAD_URL, formData, {
      authHeader: true,
    });

    return response;
  }
}

export default ActionService;
