<template>
  <v-main>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col align="center" justify="center">
          <v-img width="200" class="image-zoom" :src="imageLogo" />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style lang="scss" scoped>
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.image-zoom {
  width: 20rem;
  animation-name: zoom;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import _isEmpty from "lodash/isEmpty";

import ImageLogo from "@/assets/images/illustrations/bePro-logo.svg";

export default {
  data: () => ({
    imageLogo: ImageLogo,
    interval: null,
  }),
  computed: {
    ...mapState("authentication", {
      token: (state) => state.token,
      profile: (state) => state.profile,
    }),
  },
  methods: {
    ...mapActions("authentication", {
      setToken: "setToken",
      getProfile: "getProfile",
    }),
    redirect() {
      if (_isEmpty(this.token) || _isEmpty(this.profile)) {
        this.$router.replace({ name: "login" });

        return;
      }

      let toRoute = sessionStorage.getItem("toRoute");
      if (_isEmpty(toRoute)) {
        this.$router.replace({ name: "home" });

        return;
      }

      toRoute = JSON.parse(toRoute);
      if (_isEmpty(toRoute.name)) {
        this.$router.replace({ name: "home" });

        return;
      }

      this.$router.replace(toRoute);
    },
  },
  async mounted() {
    let query = this.$route.query;
    if (_isEmpty(this.token) && !_isEmpty(query)) {
      this.setToken(query);
    }

    if (!_isEmpty(this.token)) {
      await this.getProfile();
    }

    this.redirect();
  },
};
</script>
